import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { selectedTaSelector } from '../../agents';
import { CREATE_TASK_REQUEST, createTaskFailureAction, CreateTaskRequestAction, createTaskSuccessAction, getTaskListFailureAction, getTaskListSuccessAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { ITaskAPI, ICreateTaskRequest } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { listTaskRemindersSelector } from '../selectors';

export function* createTaskSaga(action: CreateTaskRequestAction) {

  try {
    const selectedTa = yield select(selectedTaSelector);

    const taskReminders = yield select(listTaskRemindersSelector);
    const adjustedReminders = taskReminders.map(item => ({
      channel: item.channel,
      offsetUnit: item.offsetUnit,
      offset: -Math.abs(item.offset),
    }));

    const taskToCreate: ICreateTaskRequest = {
      assigneeId: action.task.assigneeId ?? null,
      bookingHumanReadableId: action.task.bookingRef,
      categoryId: action.task.category ?? '',
      deadline: action.task.endDate + 'T' + action.task.endTime + ':00Z',
      description: action.task.description,
      priority: action.task.priority,
      reminders: adjustedReminders,
      startBy: !action.task.startByDate?.length ? null : action.task.startByDate + 'T00:00:00Z',
      status: action.task.status,
      subject: action.task.subject,
      teamId: action.task.teamId ?? '',
    };

    const taskManagementApi = makeTaskManagementApi(selectedTa?.uuid);
    const createTaskResponse: AxiosResponse<ITaskAPI> = yield call(taskManagementApi.createTask, taskToCreate); 
    yield put(createTaskSuccessAction());
    yield put(enqueueNotification({ message: 'New task created successfully.', options: { variant: 'success' } }));
    yield call(action.history.push, '/tasks');
  } catch (e) {
    yield put(createTaskFailureAction(e));
    const errorMapping = {
      BOOKING_NOT_FOUND: 'Booking Ref. is invalid',
      CATEGORY_NOT_FOUND: 'Category is invalid',
      TEAM_NOT_FOUND: 'Team is invalid',
      USER_NOT_FOUND: 'User is invalid',
    };
    const errors: { message: string; errorCode: string; }[] = e?.response?.data?.errors ?? [];
    let isErrorDisplayed = false;
    for (const error of errors) {
      const errorMessage = errorMapping[error.errorCode];
      if (errorMessage) {
        isErrorDisplayed = true;
        yield put(enqueueNotification({ message: errorMessage, options: { variant: 'error' } }));
      }
    }
    if (!isErrorDisplayed) {
      yield put(enqueueNotification({ message: 'Error creating task', options: { variant: 'error' } }));
    }
  }
}

export function* watchCreateTaskSaga() {
  yield takeLatest(
    [CREATE_TASK_REQUEST],
    createTaskSaga
  );
}
