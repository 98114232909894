import { ITaskFormValues } from "containers/TaskManagement/taskFormValidation";
import { History } from 'history';
import { IUser } from "services/BackendApi";
import { ETasksSortableKey, ITask, ITaskAttachment, ITaskCategory, ITaskComment, ITaskReminder, ITaskTeam, TReminderChannel, TReminderOffsetUnit } from "services/TaskManagementApi/types/TaskManagementResponse";
import { ESortOrder } from "store/common/types";

export const GET_CATEGORIES_REQUEST = 'taskManagement/GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'taskManagement/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'taskManagement/GET_CATEGORIES_FAILURE';

export const GET_TEAMS_REQUEST = 'taskManagement/GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'taskManagement/GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'taskManagement/GET_TEAMS_FAILURE';

export const GET_USERS_REQUEST = 'taskManagement/GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'taskManagement/GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'taskManagement/GET_USERS_FAILURE';

export const GET_TASKS_REQUEST = 'taskManagement/GET_TASKS_REQUEST';
export const GET_TASKS_SUCCESS = 'taskManagement/GET_TASKS_SUCCESS';
export const GET_TASKS_FAILURE = 'taskManagement/GET_TASKS_FAILURE';

export const SET_ID_FILTER = 'taskManagement/SET_ID_FILTER';
export const SET_CATEGORY_FILTER = 'taskManagement/SET_CATEGORY_FILTER';
export const SET_STATUS_FILTER = 'taskManagement/SET_STATUS_FILTER';
export const SET_TEAM_FILTER = 'taskManagement/SET_TEAM_FILTER';
export const SET_ASSIGNEE_FILTER = 'taskManagement/SET_ASSIGNEE_FILTER';
export const SET_PRIORITY_FILTER = 'taskManagement/SET_PRIORITY_FILTER';
export const SET_DATES_FILTER = 'taskManagement/SET_DATES_FILTER';
export const SET_BOOKING_REF_FILTER = 'taskManagement/SET_BOOKING_REF_FILTER';
export const SET_GUEST_NAME_FILTER = 'taskManagement/SET_GUEST_NAME_FILTER';
export const SET_REPORTER_FILTER = 'taskManagement/SET_REPORTER_FILTER';

export const GET_FILTER_INFO_REQUEST = 'taskManagement/GET_FILTER_INFO_REQUEST';
export const GET_FILTER_INFO_SUCCESS = 'taskManagement/GET_FILTER_INFO_SUCCESS';
export const GET_FILTER_INFO_FAILURE = 'taskManagement/GET_FILTER_INFO_FAILURE';

export const SEARCH_REQUEST = 'taskManagement/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'taskManagement/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'taskManagement/SEARCH_FAILURE';

export const SET_PAGE_NUMBER = 'taskManagement/SET_PAGE_NUMBER';
export const SET_SORT = 'taskManagement/SET_SORT';
export const SET_ITEMS_PER_PAGE = 'taskManagement/SET_ITEMS_PER_PAGE';

export const RESET_FILTERS = 'taskManagement/RESET_FILTERS';

export const DELETE_TASK_REQUEST = 'taskManagement/DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'taskManagement/DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'taskManagement/DELETE_TASK_FAILURE';

export const CREATE_TASK_REQUEST = 'taskManagement/CREATE_TASK_REQUEST';
export const CREATE_TASK_SUCCESS = 'taskManagement/CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'taskManagement/CREATE_TASK_FAILURE';

export const EDIT_TASK_REQUEST = 'taskManagement/EDIT_TASK_REQUEST';
export const EDIT_TASK_SUCCESS = 'taskManagement/EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAILURE = 'taskManagement/EDIT_TASK_FAILURE';

export const GET_SELECTED_TASK_REQUEST = 'taskManagement/GET_SELECTED_TASK_REQUEST';
export const GET_SELECTED_TASK_SUCCESS = 'taskManagement/GET_SELECTED_TASK_SUCCESS';
export const GET_SELECTED_TASK_FAILURE = 'taskManagement/GET_SELECTED_TASK_FAILURE';

export const CREATE_TASK_COMMENT_REQUEST = 'taskManagement/CREATE_TASK_COMMENT_REQUEST';
export const CREATE_TASK_COMMENT_SUCCESS = 'taskManagement/CREATE_TASK_COMMENT_SUCCESS';
export const CREATE_TASK_COMMENT_FAILURE = 'taskManagement/CREATE_TASK_COMMENT_FAILURE';

export const ADD_FOCUSED_COMMENT = 'taskManagement/ADD_FOCUSED_COMMENT';
export const REMOVE_FOCUSED_COMMENT = 'taskManagement/REMOVE_FOCUSED_COMMENT';

export const LIST_TASK_COMMENTS_REQUEST = 'taskManagement/LIST_TASK_COMMENTS_REQUEST';
export const LIST_TASK_COMMENTS_SUCCESS = 'taskManagement/LIST_TASK_COMMENTS_SUCCESS';
export const LIST_TASK_COMMENTS_FAILURE = 'taskManagement/LIST_TASK_COMMENTS_FAILURE';

export const DELETE_COMMENT_REQUEST = 'taskManagement/DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'taskManagement/DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'taskManagement/DELETE_COMMENT_FAILURE';

export const SET_SELECTED_COMMENT_ID = 'taskManagement/SET_SELECTED_COMMENT_ID';

export const ADD_TASK_REMINDER = 'taskManagement/ADD_TASK_REMINDER';
export const CHANGE_TASK_REMINDER = 'taskManagement/CHANGE_TASK_REMINDER';
export const LIST_TASK_REMINDERS_SUCCESS = 'taskManagement/LIST_TASK_REMINDERS_SUCCESS';
export const DELETE_TASK_REMINDER = 'taskManagement/DELETE_TASK_REMINDER';
export const CLEAR_TASK_REMINDERS = 'taskManagement/CLEAR_TASK_REMINDERS';

export const OPEN_UPLOAD_TASK_ATTACHMENT_MODAL = 'taskManagement/OPEN_UPLOAD_TASK_ATTACHMENT_MODAL';
export const CLOSE_UPLOAD_TASK_ATTACHMENT_MODAL = 'taskManagement/CLOSE_UPLOAD_TASK_ATTACHMENT_MODAL';
export const SET_ATTACHMENT_TO_UPLOAD = 'taskManagement/SET_ATTACHMENTS_TO_UPLOAD';
export const SET_ATTACHMENT_DISPLAY_NAME = 'taskManagement/SET_ATTACHMENTS_DISPLAY_NAME';

export const UPLOAD_ATTACHMENT_REQUEST = 'taskManagement/UPLOAD_ATTACHMENT_REQUEST';
export const UPLOAD_ATTACHMENT_SUCCESS = 'taskManagement/UPLOAD_ATTACHMENT_SUCCESS';
export const UPLOAD_ATTACHMENT_FAILURE = 'taskManagement/UPLOAD_ATTACHMENT_FAILURE';

export const LIST_ATTACHMENTS_REQUEST = 'taskManagement/LIST_ATTACHMENTS_REQUEST';
export const LIST_ATTACHMENTS_SUCCESS = 'taskManagement/LIST_ATTACHMENTS_SUCCESS';
export const LIST_ATTACHMENTS_FAILURE = 'taskManagement/LIST_ATTACHMENTS_FAILURE';

export const DELETE_ATTACHMENT_REQUEST = 'taskManagement/DELETE_ATTACHMENT_REQUEST';
export const DELETE_ATTACHMENT_SUCCESS = 'taskManagement/DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAILURE = 'taskManagement/DELETE_ATTACHMENT_FAILURE';

export const SET_SELECTED_ATTACHMENT_ID = 'taskManagement/SET_SELECTED_ATTACHMENT_ID';

export type GetCategoriesRequestAction = ReturnType<typeof getCategoriesRequestAction>;
export const getCategoriesRequestAction = () => ({
  type: GET_CATEGORIES_REQUEST as typeof GET_CATEGORIES_REQUEST,
});

export type GetCategoriesSuccessAction = ReturnType<typeof getCategoriesSuccessAction>;
export const getCategoriesSuccessAction = (tasks: any[], totalResults: number) => ({
  type: GET_CATEGORIES_SUCCESS as typeof GET_CATEGORIES_SUCCESS,
  tasks,
  totalResults,
});

export type GetCategoriesFailureAction = ReturnType<typeof getCategoriesFailureAction>;
export const getCategoriesFailureAction = (error: any) => ({
  type: GET_CATEGORIES_FAILURE as typeof GET_CATEGORIES_FAILURE,
  error,
});

export type GetTeamsRequestAction = ReturnType<typeof getTeamsRequestAction>;
export const getTeamsRequestAction = () => ({
  type: GET_TEAMS_REQUEST as typeof GET_TEAMS_REQUEST,
});

export type GetTeamsSuccessAction = ReturnType<typeof getTeamsSuccessAction>;
export const getTeamsSuccessAction = (tasks: any[], totalResults: number) => ({
  type: GET_TEAMS_SUCCESS as typeof GET_TEAMS_SUCCESS,
  tasks,
  totalResults,
});

export type GetTeamsFailureAction = ReturnType<typeof getTeamsFailureAction>;
export const getTeamsFailureAction = (error: any) => ({
  type: GET_TEAMS_FAILURE as typeof GET_TEAMS_FAILURE,
  error,
});

export type GetUsersRequestAction = ReturnType<typeof getUsersRequestAction>;
export const getUsersRequestAction = (filterTerm: string, typeOfUser: 'assignee' | 'reporter' | 'both') => ({
  type: GET_USERS_REQUEST as typeof GET_USERS_REQUEST,
  filterTerm,
  typeOfUser,
});

export type GetUsersSuccessAction = ReturnType<typeof getUsersSuccessAction>;
export const getUsersSuccessAction = (users: Pick<IUser, 'uuid' | 'firstName' | 'lastName' | 'email'>[], typeOfUser: 'assignee' | 'reporter' | 'both') => ({
  type: GET_USERS_SUCCESS as typeof GET_USERS_SUCCESS,
  users,
  typeOfUser,
});

export type GetUsersFailureAction = ReturnType<typeof getUsersFailureAction>;
export const getUsersFailureAction = (error: any, typeOfUser: 'assignee' | 'reporter' | 'both') => ({
  type: GET_USERS_FAILURE as typeof GET_USERS_FAILURE,
  typeOfUser,
  error,
});

export type GetTaskListRequestAction = ReturnType<typeof getTaskListRequestAction>;
export const getTaskListRequestAction = () => ({
  type: GET_TASKS_REQUEST as typeof GET_TASKS_REQUEST,
});

export type GetTaskListSuccessAction = ReturnType<typeof getTaskListSuccessAction>;
export const getTaskListSuccessAction = (tasks: ITask[], totalResults: number) => ({
  type: GET_TASKS_SUCCESS as typeof GET_TASKS_SUCCESS,
  tasks,
  totalResults,
});

export type GetTaskListFailureAction = ReturnType<typeof getTaskListFailureAction>;
export const getTaskListFailureAction = (error: any) => ({
  type: GET_TASKS_FAILURE as typeof GET_TASKS_FAILURE,
  error,
});

export type GetFilterInfoRequestAction = ReturnType<typeof getFilterInfoRequestAction>;
export const getFilterInfoRequestAction = () => ({
  type: GET_FILTER_INFO_REQUEST as typeof GET_FILTER_INFO_REQUEST,
});

export type GetFilterInfoSuccessAction = ReturnType<typeof getFilterInfoSuccessAction>;
export const getFilterInfoSuccessAction = (categories: ITaskCategory[], teams: ITaskTeam[] ) => ({
  type: GET_FILTER_INFO_SUCCESS as typeof GET_FILTER_INFO_SUCCESS,
  categories,
  teams,
});

export type GetFilterInfoFailureAction = ReturnType<typeof getFilterInfoFailureAction>;
export const getFilterInfoFailureAction = (error: any) => ({
  type: GET_FILTER_INFO_FAILURE as typeof GET_FILTER_INFO_FAILURE,
  error,
});

export type SearchRequestAction = ReturnType<typeof searchRequestAction>;
export const searchRequestAction = () => ({
  type: SEARCH_REQUEST as typeof SEARCH_REQUEST,
});

export type SearchSuccessAction = ReturnType<typeof searchSuccessAction>;
export const searchSuccessAction = (tasks: any[], totalResults: number) => ({
  type: SEARCH_SUCCESS as typeof SEARCH_SUCCESS,
  tasks,
  totalResults,
});

export type SearchFailureAction = ReturnType<typeof searchFailureAction>;
export const searchFailureAction = (error: any) => ({
  type: SEARCH_FAILURE as typeof SEARCH_FAILURE,
  error,
});


export type SetIdFilterAction = ReturnType<typeof setIdFilterAction>;
export const setIdFilterAction = (value: string) => ({
  type: SET_ID_FILTER as typeof SET_ID_FILTER,
  value,
});

export type SetCategoryFilterAction = ReturnType<typeof setCategoryFilterAction>;
export const setCategoryFilterAction = (value: string[]) => ({
  type: SET_CATEGORY_FILTER as typeof SET_CATEGORY_FILTER,
  value,
});  

export type SetStatusFilterAction = ReturnType<typeof setStatusFilterAction>;
export const setStatusFilterAction = (value: string[]) => ({
  type: SET_STATUS_FILTER as typeof SET_STATUS_FILTER,
  value,
});  

export type SetTeamFilterAction = ReturnType<typeof setTeamFilterAction>;
export const setTeamFilterAction = (value: string) => ({
  type: SET_TEAM_FILTER as typeof SET_TEAM_FILTER,
  value,
});  

export type SetAssigneeFilterAction = ReturnType<typeof setAssigneeFilterAction>;
export const setAssigneeFilterAction = (value: string) => ({
  type: SET_ASSIGNEE_FILTER as typeof SET_ASSIGNEE_FILTER,
  value,
});  

export type SetPriorityFilterAction = ReturnType<typeof setPriorityFilterAction>;
export const setPriorityFilterAction = (value: string) => ({
  type: SET_PRIORITY_FILTER as typeof SET_PRIORITY_FILTER,
  value,
});  

export type SetDatesFilterAction = ReturnType<typeof setDatesFilterAction>;
export const setDatesFilterAction = (value: string[]) => ({
  type: SET_DATES_FILTER as typeof SET_DATES_FILTER,
  value,
});  

export type SetBookingRefFilterAction = ReturnType<typeof setBookingRefFilterAction>;
export const setBookingRefFilterAction = (value: string) => ({
  type: SET_BOOKING_REF_FILTER as typeof SET_BOOKING_REF_FILTER,
  value,
});  

export type SetGuestNameFilterAction = ReturnType<typeof setGuestNameFilterAction>;
export const setGuestNameFilterAction = (value: string) => ({
  type: SET_GUEST_NAME_FILTER as typeof SET_GUEST_NAME_FILTER,
  value,
});  

export type SetReporterFilterAction = ReturnType<typeof setReporterFilterAction>;
export const setReporterFilterAction = (value: string) => ({
  type: SET_REPORTER_FILTER as typeof SET_REPORTER_FILTER,
  value,
});  

export type SetPageNumberAction = ReturnType<typeof setPageNumberAction>;
export const setPageNumberAction = (pageNumber: number) => ({
  type: SET_PAGE_NUMBER as typeof SET_PAGE_NUMBER,
  pageNumber,
});

export type SetSortAction = ReturnType<typeof setSortAction>;
export const setSortAction = (sortBy: ETasksSortableKey, sortOrder: ESortOrder) => ({
  type: SET_SORT as typeof SET_SORT,
  sortBy,
  sortOrder
});

export type SetItemsPerPageAction = ReturnType<typeof setItemsPerPageAction>;
export const setItemsPerPageAction = (itemsPerPage: number) => ({
  type: SET_ITEMS_PER_PAGE as typeof SET_ITEMS_PER_PAGE,
  itemsPerPage,
});

export type DeleteTaskRequestAction = ReturnType<typeof deleteTaskRequestAction>;
export const deleteTaskRequestAction = (taskId: string) => ({
  type: DELETE_TASK_REQUEST as typeof DELETE_TASK_REQUEST,
  taskId,
});

export type DeleteTaskSuccessAction = ReturnType<typeof deleteTaskSuccessAction>;
export const deleteTaskSuccessAction = () => ({
  type: DELETE_TASK_SUCCESS as typeof DELETE_TASK_SUCCESS,
});

export type DeleteTaskFailureAction = ReturnType<typeof deleteTaskFailureAction>;
export const deleteTaskFailureAction = () => ({
  type: DELETE_TASK_FAILURE as typeof DELETE_TASK_FAILURE,
});

export type CreateTaskRequestAction = ReturnType<typeof createTaskRequestAction>;
export const createTaskRequestAction = (taskFormValues: ITaskFormValues, history: History) => ({
  type: CREATE_TASK_REQUEST as typeof CREATE_TASK_REQUEST,
  task: taskFormValues,
  history,
});

export type CreateTaskSuccessAction = ReturnType<typeof createTaskSuccessAction>;
export const createTaskSuccessAction = () => ({
  type: CREATE_TASK_SUCCESS as typeof CREATE_TASK_SUCCESS,
});

export type CreateTaskFailureAction = ReturnType<typeof createTaskFailureAction>;
export const createTaskFailureAction = (error: any) => ({
  type: CREATE_TASK_FAILURE as typeof CREATE_TASK_FAILURE,
  error,
});

export type EditTaskRequestAction = ReturnType<typeof editTaskRequestAction>;
export const editTaskRequestAction = (taskFormValues: ITaskFormValues, history: History, taskId: string) => ({
  type: EDIT_TASK_REQUEST as typeof EDIT_TASK_REQUEST,
  task: taskFormValues,
  taskId,
  history,
});

export type EditTaskSuccessAction = ReturnType<typeof editTaskSuccessAction>;
export const editTaskSuccessAction = () => ({
  type: EDIT_TASK_SUCCESS as typeof EDIT_TASK_SUCCESS,
});

export type EditTaskFailureAction = ReturnType<typeof editTaskFailureAction>;
export const editTaskFailureAction = (error: any) => ({
  type: EDIT_TASK_FAILURE as typeof EDIT_TASK_FAILURE,
  error,
});

export type CreateTaskCommentRequestAction = ReturnType<typeof createTaskCommentRequestAction>;
export const createTaskCommentRequestAction = (taskId: string, comment: string, commentId: string | null) => ({
  type: CREATE_TASK_COMMENT_REQUEST as typeof CREATE_TASK_COMMENT_REQUEST,
  taskId,
  commentId,
  comment,
});

export type CreateTaskCommentSuccessAction = ReturnType<typeof createTaskCommentSuccessAction>;
export const createTaskCommentSuccessAction = () => ({
  type: CREATE_TASK_COMMENT_SUCCESS as typeof CREATE_TASK_COMMENT_SUCCESS,
});

export type CreateTaskCommentFailureAction = ReturnType<typeof createTaskCommentFailureAction>;
export const createTaskCommentFailureAction = (error: any) => ({
  type: CREATE_TASK_COMMENT_FAILURE as typeof CREATE_TASK_COMMENT_FAILURE,
  error,
});

export type ListTaskCommentRequestAction = ReturnType<typeof listTaskCommentsRequestAction>;
export const listTaskCommentsRequestAction = (taskId: string) => ({
  type: LIST_TASK_COMMENTS_REQUEST as typeof LIST_TASK_COMMENTS_REQUEST,
  taskId,
});

export type ListTaskCommentSuccessAction = ReturnType<typeof listTaskCommentsSuccessAction>;
export const listTaskCommentsSuccessAction = (taskComments: ITaskComment[]) => ({
  type: LIST_TASK_COMMENTS_SUCCESS as typeof LIST_TASK_COMMENTS_SUCCESS,
  taskComments,
});

export type ListTaskCommentFailureAction = ReturnType<typeof listTaskCommentsFailureAction>;
export const listTaskCommentsFailureAction = (error: any) => ({
  type: LIST_TASK_COMMENTS_FAILURE as typeof LIST_TASK_COMMENTS_FAILURE,
  error,
});

export type GetSelectedTaskRequestAction = ReturnType<typeof getSelectedTaskRequestAction>;
export const getSelectedTaskRequestAction = (taskId: string) => ({
  type: GET_SELECTED_TASK_REQUEST as typeof GET_SELECTED_TASK_REQUEST,
  taskId
});

export type GetSelectedTaskSuccessAction = ReturnType<typeof getSelectedTaskSuccessAction>;
export const getSelectedTaskSuccessAction = (task: ITask) => ({
  type: GET_SELECTED_TASK_SUCCESS as typeof GET_SELECTED_TASK_SUCCESS,
  task,
});

export type GetSelectedTaskFailureAction = ReturnType<typeof getSelectedTaskFailureAction>;
export const getSelectedTaskFailureAction = (error: any) => ({
  type: GET_SELECTED_TASK_FAILURE as typeof GET_SELECTED_TASK_FAILURE,
  error,
});

export type AddFocusedCommentAction = ReturnType<typeof addFocusedCommentAction>;
export const addFocusedCommentAction = (commentUuid: string | null) => ({
  type: ADD_FOCUSED_COMMENT as typeof ADD_FOCUSED_COMMENT,
  commentUuid
});

export type RemoveFocusedCommentAction = ReturnType<typeof removeFocusedCommentAction>;
export const removeFocusedCommentAction = (commentUuid: string | null) => ({
  type: REMOVE_FOCUSED_COMMENT as typeof REMOVE_FOCUSED_COMMENT,
  commentUuid
});

export type ResetFiltersAction = ReturnType<typeof resetFiltersAction>;
export const resetFiltersAction = () => ({
  type: RESET_FILTERS as typeof RESET_FILTERS,
});  

export type DeleteCommentRequestAction = ReturnType<typeof deleteCommentRequestAction>;
export const deleteCommentRequestAction = (commentId: string, taskId: string) => ({
  type: DELETE_COMMENT_REQUEST as typeof DELETE_COMMENT_REQUEST,
  commentId,
  taskId,
});

export type DeleteCommentSuccessAction = ReturnType<typeof deleteCommentSuccessAction>;
export const deleteCommentSuccessAction = () => ({
  type: DELETE_COMMENT_SUCCESS as typeof DELETE_COMMENT_SUCCESS,
});

export type DeleteCommentFailureAction = ReturnType<typeof deleteCommentFailureAction>;
export const deleteCommentFailureAction = () => ({
  type: DELETE_COMMENT_FAILURE as typeof DELETE_COMMENT_FAILURE,
});

export type SetSelectedCommentIdAction = ReturnType<typeof setSelectedCommentIdAction>;
export const setSelectedCommentIdAction = (commentId: string|null) => ({
  type: SET_SELECTED_COMMENT_ID as typeof SET_SELECTED_COMMENT_ID,
  commentId,
});

export type AddTaskReminderAction = ReturnType<typeof addTaskReminderAction>;
export const addTaskReminderAction = (taskReminder: ITaskReminder) => ({
  type: ADD_TASK_REMINDER as typeof ADD_TASK_REMINDER,
  taskReminder,
});

export type ListTaskReminderSuccessAction = ReturnType<typeof listTaskRemindersSuccessAction>;
export const listTaskRemindersSuccessAction = (taskReminders: ITaskReminder[]) => ({
  type: LIST_TASK_REMINDERS_SUCCESS as typeof LIST_TASK_REMINDERS_SUCCESS,
  taskReminders,
});

export type RemoveTaskReminderAction = ReturnType<typeof removeTaskReminderAction>;
export const removeTaskReminderAction = (uuid: string) => ({
  type: DELETE_TASK_REMINDER as typeof DELETE_TASK_REMINDER,
  uuid,
});

export type ClearTaskRemindersAction = ReturnType<typeof clearTaskRemindersAction>;
export const clearTaskRemindersAction = () => ({
  type: CLEAR_TASK_REMINDERS as typeof CLEAR_TASK_REMINDERS,
});

export type ChangeTaskReminderAction = ReturnType<typeof changeTaskReminderAction>;
export const changeTaskReminderAction = (args: { uuid: string; channel?: TReminderChannel; offset?: number; offsetUnit?: TReminderOffsetUnit }) => ({
  type: CHANGE_TASK_REMINDER as typeof CHANGE_TASK_REMINDER,
  ...args,
});

export type OpenUploadAttachmentModalAction = ReturnType<typeof openUploadAttachmentModalAction>;
export const openUploadAttachmentModalAction = () => ({
  type: OPEN_UPLOAD_TASK_ATTACHMENT_MODAL as typeof OPEN_UPLOAD_TASK_ATTACHMENT_MODAL,
});

export type CloseUploadAttachmentModalAction = ReturnType<typeof closeUploadAttachmentModalAction>;
export const closeUploadAttachmentModalAction = () => ({
  type: CLOSE_UPLOAD_TASK_ATTACHMENT_MODAL as typeof CLOSE_UPLOAD_TASK_ATTACHMENT_MODAL,
});

export type SetAttachmentToUploadAction = ReturnType<typeof setAttachmentToUploadAction>;
export const setAttachmentToUploadAction = (document: File) => ({
  type: SET_ATTACHMENT_TO_UPLOAD as typeof SET_ATTACHMENT_TO_UPLOAD,
  document
});

export type SetAttachmentDisplayNameAction = ReturnType<typeof setAttachmentDisplayNameAction>;
export const setAttachmentDisplayNameAction = (displayName: string) => ({
  type: SET_ATTACHMENT_DISPLAY_NAME as typeof SET_ATTACHMENT_DISPLAY_NAME,
  displayName
});

export type UploadAttachmentRequestAction = ReturnType<typeof uploadAttachmentRequestAction>;
export const uploadAttachmentRequestAction = (taskId: string) => ({
  type: UPLOAD_ATTACHMENT_REQUEST as typeof UPLOAD_ATTACHMENT_REQUEST,
  taskId,
});

export type UploadAttachmentSuccessAction = ReturnType<typeof uploadAttachmentSuccessAction>;
export const uploadAttachmentSuccessAction = () => ({
  type: UPLOAD_ATTACHMENT_SUCCESS as typeof UPLOAD_ATTACHMENT_SUCCESS,
});

export type UploadAttachmentFailureAction = ReturnType<typeof uploadAttachmentFailureAction>;
export const uploadAttachmentFailureAction = (error: Error) => ({
  type: UPLOAD_ATTACHMENT_FAILURE as typeof UPLOAD_ATTACHMENT_FAILURE,
  error,
});

export type ListAttachmentRequestAction = ReturnType<typeof listAttachmentRequestAction>;
export const listAttachmentRequestAction = (taskId: string) => ({
  type: LIST_ATTACHMENTS_REQUEST as typeof LIST_ATTACHMENTS_REQUEST,
  taskId,
});

export type ListAttachmentSuccessAction = ReturnType<typeof listAttachmentSuccessAction>;
export const listAttachmentSuccessAction = (attachments: ITaskAttachment[]) => ({
  type: LIST_ATTACHMENTS_SUCCESS as typeof LIST_ATTACHMENTS_SUCCESS,
  attachments,
});

export type ListAttachmentFailureAction = ReturnType<typeof listAttachmentFailureAction>;
export const listAttachmentFailureAction = (error: Error) => ({
  type: LIST_ATTACHMENTS_FAILURE as typeof LIST_ATTACHMENTS_FAILURE,
  error,
});

export type DeleteAttachmentRequestAction = ReturnType<typeof deleteAttachmentRequestAction>;
export const deleteAttachmentRequestAction = (taskId: string) => ({
  type: DELETE_ATTACHMENT_REQUEST as typeof DELETE_ATTACHMENT_REQUEST,
  taskId,
});

export type DeleteAttachmentSuccessAction = ReturnType<typeof deleteAttachmentSuccessAction>;
export const deleteAttachmentSuccessAction = () => ({
  type: DELETE_ATTACHMENT_SUCCESS as typeof DELETE_ATTACHMENT_SUCCESS,
});

export type DeleteAttachmentFailureAction = ReturnType<typeof deleteAttachmentFailureAction>;
export const deleteAttachmentFailureAction = () => ({
  type: DELETE_ATTACHMENT_FAILURE as typeof DELETE_ATTACHMENT_FAILURE,
});

export type SetSelectedAttachmentIdAction = ReturnType<typeof setSelectedAttachmentIdAction>;
export const setSelectedAttachmentIdAction = (attachmentId: string|null) => ({
  type: SET_SELECTED_ATTACHMENT_ID as typeof SET_SELECTED_ATTACHMENT_ID,
  attachmentId,
});
export type TaskManagementAction =
  | GetCategoriesRequestAction
  | GetCategoriesSuccessAction
  | GetCategoriesFailureAction
  | GetTeamsRequestAction
  | GetTeamsSuccessAction
  | GetTeamsFailureAction
  | GetUsersRequestAction
  | GetUsersSuccessAction
  | GetUsersFailureAction
  | GetFilterInfoRequestAction
  | GetFilterInfoSuccessAction
  | GetFilterInfoFailureAction
  | ResetFiltersAction
  | SearchRequestAction
  | SearchSuccessAction
  | SearchFailureAction
  | GetTaskListRequestAction
  | GetTaskListSuccessAction
  | GetTaskListFailureAction
  | SetIdFilterAction
  | SetCategoryFilterAction
  | SetStatusFilterAction
  | SetTeamFilterAction
  | SetAssigneeFilterAction
  | SetPriorityFilterAction
  | SetDatesFilterAction
  | SetBookingRefFilterAction
  | SetGuestNameFilterAction
  | SetReporterFilterAction
  | SetPageNumberAction
  | SetSortAction
  | SetItemsPerPageAction
  | DeleteTaskRequestAction
  | DeleteTaskSuccessAction
  | DeleteTaskFailureAction
  | CreateTaskRequestAction
  | CreateTaskSuccessAction
  | CreateTaskFailureAction
  | GetSelectedTaskRequestAction
  | GetSelectedTaskSuccessAction
  | GetSelectedTaskFailureAction
  | ListTaskCommentRequestAction
  | ListTaskCommentSuccessAction
  | ListTaskCommentFailureAction
  | AddFocusedCommentAction
  | RemoveFocusedCommentAction
  | CreateTaskCommentRequestAction
  | CreateTaskCommentSuccessAction
  | CreateTaskCommentFailureAction
  | EditTaskRequestAction
  | EditTaskSuccessAction
  | EditTaskFailureAction
  | DeleteCommentRequestAction
  | DeleteCommentSuccessAction
  | DeleteCommentFailureAction
  | SetSelectedCommentIdAction
  | AddTaskReminderAction
  | RemoveTaskReminderAction
  | ChangeTaskReminderAction
  | ListTaskReminderSuccessAction
  | SetAttachmentToUploadAction
  | SetAttachmentDisplayNameAction
  | UploadAttachmentRequestAction
  | UploadAttachmentSuccessAction
  | UploadAttachmentFailureAction
  | ListAttachmentRequestAction
  | ListAttachmentSuccessAction
  | ListAttachmentFailureAction
  | OpenUploadAttachmentModalAction
  | CloseUploadAttachmentModalAction
  | DeleteAttachmentRequestAction
  | DeleteAttachmentSuccessAction
  | DeleteAttachmentFailureAction
  | ClearTaskRemindersAction
  | SetSelectedAttachmentIdAction;
